<template>
  <div class="wrap">
    <div class="inner">
      <div class="line" :style="{ background: activeColor }"></div>
      <div class="words" :style="{ color: textColor }">
        <span>{{ title }}</span>
      </div>
      <div class="line" :style="{ background: activeColor }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    title: {
      type: String,
      required: true,
      default: "none"
    },
    activeColor: {
      type: String,
      required: false,
      default: "#0079eb"
    },
    textColor: {
      type: String,
      required: false,
      default: "#000000a6"
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  @media screen and (max-width: 540px) {
    margin: 6px 0;
    font-size: 8px;
  }
  @media screen and (min-width: 540px) {
    margin: 16px 0;
    font-size: 16px;
  }
}
.inner {
  display: flex;
  justify-content: center;
}
.words {
  color: #000000a6;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  & > span {
    position: relative;
    display: block;
    padding: 0 10px;
    white-space: nowrap;
    z-index: 109;
  }
}
.line {
  position: relative;
  top: 12px;
  @media screen and (max-width: 540px) {
    width: 20px;
  }
  @media screen and (min-width: 540px) {
    width: 40px;
  }
  height: 2px;
  z-index: 99;
}
</style>
