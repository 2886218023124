import Vue from "vue"

// 引入SCSS
import "../style/index.scss"
import "../style/Tailwind.scss"

// 引入aos aos为页面滚动动画插件 具体可看 https://www.jq22.com/yanshi8150 效果
import "aos/dist/aos.css"

// 引入全局组件(定义后每个页面不用重复引入)
import CustomHeader from "@/components/Header/Header.vue"
import CustomFooter from "@/components/Footer/Footer.vue"
import Fixed from "@/components/Fixed/Fixed.vue"
import Title from "@/components/Title/Title.vue"
import swiper from "@/components/swiper/swiper.vue"
import dialog from "@/components/dialog/dialog.vue"

Vue.component("CustomHeader", CustomHeader)
Vue.component("CustomFooter", CustomFooter)
Vue.component("Fixed", Fixed)
Vue.component("Title", Title)
Vue.component("CustomSwiper", swiper)
Vue.component("CustomDialog", dialog)

//引入Swiper
import Swiper from "@/assets/js/swiper-bundle.min.js"
import "@/assets/css/swiper-bundle.min.css"
Vue.prototype.Swiper = Swiper

//ElementUI
import Element from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
Vue.use(Element)

//Antd
import Antd from "ant-design-vue"
import "ant-design-vue/dist/antd.css"
Vue.use(Antd)
