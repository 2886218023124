/**
 * 设置浏览器头部标题
 * @param {title} string 标题名称
 */
export const setTitle = title => {
  title = title ? `${title}` : "领航无人机-杭州艾航科技有限公司"
  document.title = "领航无人机-杭州艾航科技有限公司-" + title
}

/**
 * 设置锚点 跳转定位用
 */

export const scrollInto = link => {
  const hash = link || window.location.hash
  let target = null
  if (hash) target = document.querySelector(hash)
  if (target) {
    target.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    })
  }
}
