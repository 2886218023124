<template>
  <div>
    <!-- PC端顶部 -->
    <header class="header is_fixed" :style="{ background: back }" :class="isHover ? 'header-hover' : ''">
      <div class="header-wrap">
        <div class="logo">
          <img v-if="logo" src="../../assets/img/header/logo.png" alt="logo" />
          <img v-else src="../../assets/img/header/logo-t.png" alt="logo" />
        </div>
        <ul class="nav" :style="{ color: color }" :class="isHover ? 'nav-hover' : ''">
          <li v-for="(item, index) in navList" :key="index" :class="[{ activeItem: item.active }]" @mouseover="mouseOver(index)" @mouseleave="mouseOut(index)" @click="skipRoute(item, index)">
            {{ item.title }}
            <i v-if="index == 1" class="el-icon-arrow-down icon" :class="flag ? 'icon-hover' : ''"></i>
          </li>
        </ul>
      </div>
      <!-- 下拉部分  -->
      <div class="header-inner" :class="flag ? 'header-inner-hover' : ''" @mouseover="mouseOver(1)" @mouseleave="mouseOut($event, 1)">
        <div class="core down" :class="flag ? 'down-hover' : ''">
          <div class="flex down-item">
            <div>
              <img src="../../assets/img/home/down1.png" />
            </div>
            <div>
              <p class="big" @click="skipPlan('electric')">智慧电力应用</p>
              <p @click.stop="skipPlan('electric#comprehensive')">无人机综合应用解决方案</p>
              <p @click.stop="skipPlan('electric#transport')">输电巡检无人机应用</p>
              <p @click.stop="skipPlan('electric#match')">配电巡检无人机应用</p>
              <p @click.stop="skipPlan('electric#transformer')">变电站巡检无人机应用</p>
            </div>
          </div>
          <div class="flex down-item" @click="skipPlan('city')">
            <div>
              <img src="../../assets/img/home/down2.png" />
            </div>
            <div>
              <p class="big" @click="skipPlan('city')">智慧城市应用</p>
              <p @click.stop="skipPlan('city#environment')">环境治理</p>
              <p @click.stop="skipPlan('city#gongan')">智慧公安</p>
              <p @click.stop="skipPlan('city#firecontrol')">消防应急</p>
              <p @click.stop="skipPlan('city#traffic')">交通管理</p>
              <p @click.stop="skipPlan('city#Infrastructure')">智慧基建</p>
            </div>
          </div>
          <div class="flex down-item" @click="skipPlan('uav')">
            <div>
              <img src="../../assets/img/home/down3.png" />
            </div>
            <div>
              <p class="big" @click="skipPlan('uav')">无人机多行业应用</p>
              <p @click.stop="skipPlan('uav#petroleum')">石油化工</p>
              <p @click.stop="skipPlan('uav#communication')">通信行业</p>
              <p @click.stop="skipPlan('uav#energy')">新型能源</p>
              <p @click.stop="skipPlan('uav#agriculture')">农业植保</p>
              <p @click.stop="skipPlan('uav#forestry')">林业保护</p>
            </div>
          </div>
        </div>
      </div>
    </header>


    <!-- 移动端顶部 -->
    <header class="header media" :class="isHover ? 'header-hover' : ''">
      <div class="wrap">
        <div @click="drawerOpen">
          <a-icon class="aicon" :class="isHover ? 'nav-hover' : ''" type="unordered-list" />
        </div>
        <div>
          <div class="logo">
            <img v-if="logo" src="../../assets/img/header/logo.png" alt="logo" />
            <img v-else src="../../assets/img/header/logo-t.png" alt="logo" />
          </div>
        </div>
        <div @click="logOpen"><i class="el-icon-service" :class="isHover ? 'nav-hover' : ''"></i></div>
      </div>
    </header>
    <!-- 移动端点击顶部左侧抽屉 -->
    <a-drawer width="65vw" placement="left" :closable="false" :visible="drawer" :maskClosable="true">
      <div class="close" @click="drawerOpen">
        <i class="el-icon-close"></i>
      </div>
      <div class="d-item" :class="[{ activeMedia: routerIndex == 0 }]" @click="skipRoute({ url: '' })">首页</div>
      <div class="d-item">
        <div @click="down = !down">行业解决方案 <i class="el-icon-arrow-down d-icon"></i></div>
        <el-collapse-transition>
          <div class="d-down" v-show="down">
            <div @click="skipPlan('electric')" :class="[{ activeMedia: routerIndex == 1 && routerType == 1 }]">智慧电力应用</div>
            <div @click="skipPlan('city')" :class="[{ activeMedia: routerIndex == 1 && routerType == 2 }]">智慧城市应用</div>
            <div @click="skipPlan('uav')" :class="[{ activeMedia: routerIndex == 1 && routerType == 3 }]">无人机多行业应用</div>
          </div>
        </el-collapse-transition>
      </div>
      <div class="d-item" :class="[{ activeMedia: routerIndex == 2 }]" @click="skipRoute({ url: 'custom' })">客户案例</div>
      <div class="d-item" :class="[{ activeMedia: routerIndex == 3 }]" style="border: none" @click="skipRoute({ url: 'about' })">关于我们</div>
    </a-drawer>
    <div v-if="drawer" class="mask-wrap" @click="maskClose"></div>
    <!-- 移动端点击联系我们出现弹窗 -->
    <CustomDialog ref="log"></CustomDialog>
  </div>
</template>

<script>
import { scrollInto } from "../../utils/util"
export default {
  name: "Header",
  props: {
    back: {
      type: String,
      required: false,
      default: ""
    },
    color: {
      type: String,
      required: false,
      default: "#fff"
    }
  },
  data() {
    return {
      isHover: false,
      logo: false,
      flag: false,
      full: false,
      drawer: false,
      down: false,
      routerIndex: 1,
      routerType: 1,
      Top: 50,
      navList: [
        {
          title: "首页",
          url: "",
          active: false
        },
        {
          title: "行业应用解决方案",
          active: false
        },
        {
          title: "客户案例",
          url: "custom",
          active: false
        },
        {
          title: "关于我们",
          url: "about",
          active: false
        }
      ]
    }
  },
  mounted() {
    const index = (this.routerIndex = this.$route.meta.index)
    if (typeof index == "number" && index > -1) this.navList[index].active = true
    if (index == 1) {
      this.down = true
      this.routerType = this.$route.meta.type
    } else {
      this.down = false
    }
    window.onscroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > this.Top) this.isHover = this.logo = true
      else this.isHover = this.logo = false
    }
  },
  methods: {
    drawerOpen() {
      this.drawer = !this.drawer
    },
    logOpen() {
      this.$refs.log.flag = true
    },
    maskClose() {
      this.drawer = !this.drawer
    },
    mouseOver(index) {
      if (index == 1) this.flag = this.isHover = this.logo = true
    },
    mouseOut(e, index) {
      if (index == 1) {
        this.flag = false
        if (document.documentElement.scrollTop < this.Top) {
          this.isHover = false
          if (!this.full) this.logo = false
        }
      }
    },
    skipRoute(item, index) {
      if (this.$route.fullPath == `/${item.url}`) return
      if (index == 1) return
      if (this.drawer) this.drawer = false
      this.$router.push(`/${item.url}`)
      document.documentElement.scrollTop = 0
    },
    skipPlan(url) {
      const list = url.split("#")
      if (this.$route.path == `/${list[0]}`) {
        scrollInto(`#${list[1]}`)
        return
      }
      document.documentElement.scrollTop = 0
      this.$router.push(`/${url}`)
    }
  }
}
</script>

<style scoped lang="scss">
$main-width: 1200px;
$header-height: 70px;
p,
ul {
  margin: 0;
}
.is_fixed {
  position: fixed;
  top: 0;
  @media screen and (max-width: 540px) {
    display: none;
  }
  @media screen and (min-width: 540px) {
    display: block;
  }
}
.media {
  position: fixed;
  top: 0;
  height: 44px !important;

  .wrap {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0px 34px;
    i {
      font-size: 24px;
      color: #fff;
      line-height: 44px;
    }
  }
  .logo {
    & > img {
      width: 100px;
      height: 28px;
      margin-top: 9px;
    }
  }
  @media screen and (max-width: 540px) {
    display: block;
  }
  @media screen and (min-width: 540px) {
    display: none;
  }
}
.aicon {
  position: relative;
  top: 4px;
}
.header {
  width: 100%;
  height: $header-height;
  user-select: none;
  background: transparent;
  @media screen and (max-width: 540px) {
    border-bottom: 1px solid rgba(120, 120, 128, 0.08);
  }
  @media screen and (min-width: 540px) {
    border-bottom: 1px solid rgba(26, 102, 252, 0.08);
  }
  transition: all 0.3s;
  z-index: 1000;
}
.header-wrap {
  width: $main-width;
  height: $header-height;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .logo {
    display: flex;
    align-items: center;
    & > img {
      width: 180px;
      height: 50px;
    }
  }
  .nav {
    width: 70%;
    display: flex;
    justify-content: space-between;
    line-height: $header-height;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    & > li {
      box-sizing: border-box;
      transition: all 0.5s;
      cursor: pointer;
    }
    & > li:hover {
      color: var(--RootColor);
    }
  }
}
.activeItem {
  border-bottom: 3px solid var(--RootColor);
}
.activeMedia {
  color: var(--RootColor)!important;
}
.header-hover {
  background: #fff;
  color: #000000;
}
.d-down {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  line-height: 42px;
  opacity: 0.8;
  div {
    margin-top: 5px;
  }
}
.mask-wrap {
  width: 35vw;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: transparent;
  z-index: 9999;
}
.close {
  font-size: 26px;
}
.d-item {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 38px;
  padding: 20px 0;
  border-bottom: 1px solid #555e6d;
}
.d-icon {
  float: right;
  line-height: 38px;
}
.nav-hover {
  color: #000000 !important;
}

.header-inner {
  width: 100%;
  height: 0px;
  background: #fff;
  overflow: hidden;
  z-index: 1024;
  transition: all 0.2s;
}

.header-inner-hover {
  height: 320px !important;
}
.down {
  height: 100%;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  visibility: hidden;
  .down-item {
    margin: 50px 0;
    img {
      width: 136px;
      height: 136px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 40px;
    }
    padding: 20px 40px 0 40px;
    border-right: 1px solid rgba($color: #979797, $alpha: 0.4);
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 36px;
    user-select: none;
    cursor: pointer;
  }
  .big {
    position: relative;
    top: -20px;
    height: 20px;
    font-size: 20px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 30px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  p {
    transition: all 0.3s;
  }
  p:hover {
    color: var(--RootColor);
  }
  .down-item:hover p:first-child {
    color: var(--RootColor);
  }
  & > div:first-child {
    padding: 20px 20px 0px 0;
  }
  & > div:last-child {
    border: 0;
    padding: 20px 0 0px 20px;
  }
}
.down-hover {
  transition: all 0.3s;
  transition-delay: 0.1s;
  visibility: visible;
}
.icon {
  transition: all 0.2s;
}
.icon-hover {
  transform: rotate(180deg);
}
</style>
<style>
.ant-drawer-content {
  color: #fff !important;
  background: #0d192d !important;
  z-index: 9999;
}
</style>
