<template>
  <div class="fixed">
    <transition name="el-fade-in">
      <div v-if="visible" class="fix-button is_fixed" @click="show">
        <img src="../../assets/img/home/call.png" />
      </div>
    </transition>
    <transition mode="out-in" name="el-zoom-in-center" appear>
      <div v-show="flag" class="touch">
        <div class="inner">
          <div class="close">
            <i style="color: #9d9d9d" class="el-icon-close" @click="show"></i>
          </div>
          <div class="tip mb">
            <div>
              <i class="el-icon-phone-outline"></i>
            </div>
            <div>
              <p class="t1">电话咨询</p>
              <p class="blue">0571-87858605</p>
            </div>
          </div>
          <div class="tip mb">
            <div>
              <i class="el-icon-message"></i>
            </div>
            <div>
              <p class="t1">邮件咨询</p>
              <p class="blue">xuanchaoyue@aihangtec.com</p>
            </div>
          </div>
          <div class="tip">
            <div>
              <i class="el-icon-chat-round"></i>
            </div>
            <div>
              <p class="t1">扫一扫联系我们</p>
              <div>
                <img class="code" src="../../assets/img/fixed/qrcode.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- //回到顶部按钮，element-ui组件 -->
    <el-backtop @click="close" />
  </div>
</template>
// 此为PC端右下角两个按钮，联系我们小弹窗和回到顶部
<script>
export default {
  name: "Fixed",
  data() {
    return {
      el: null,
      flag: false,
      visibilityHeight: 200,
      visible: false
    }
  },
  mounted() {
    const body = document.getElementsByTagName("body")
    body[0].onclick = e => {
      if (!e.target.closest(".fix-button") && !e.target.closest(".touch")) this.flag = false
    }
    
    // if (this.$route.name == "About") this.visible = true
    // else this.init()
    this.init()
  },
  methods: {
    init() {
      this.el = document.documentElement
      document.addEventListener("scroll", this.onScroll)
    },
    show() {
      this.flag = !this.flag
    },
    close() {
      this.flag = false
    },
    onScroll() {
      const scrollTop = this.el.scrollTop
      this.visible = scrollTop >= this.visibilityHeight
    }
  }
}
</script>

<style scoped lang="scss">
.is_fixed {
  width: 100%;
  position: fixed;
  bottom: 100px;
  right: 40px;
  z-index: 9999;
  user-select: none;
}
.fix-button {
  width: 40px;
  height: 40px;
  color: #ffffff;
  background: #fff;
  border-radius: 4px 0px 0px 4px;
  writing-mode: vertical-rl;
  border-radius: 50%;
  transition: all 0.4s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  img {
    width: 20px;
  }
}

.fix-button:hover {
  box-shadow: 1px 2px 10px rgb(#fff, 75%);
  background-color: #f2f6fc;
}

.touch {
  position: fixed;
  bottom: 45px;
  right: 100px;
  z-index: 999;
  width: 335px;
  height: 375px;
  background-image: url(../../assets/img/fixed/touch.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.inner {
  padding: 40px 30px 30px;
  .close {
    position: absolute;
    right: 30px;
    height: 30px;
    padding-right: 10px;
    cursor: pointer;
    i {
      float: right;
    }
  }
}

.t1 {
  user-select: none;
}
.tip {
  display: flex;
  justify-content: flex-start;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;

  i {
    margin-right: 6px;
  }
}
.code {
  width: 90px;
  height: 90px;
  margin-top: 8px;
}
.mb {
  margin-bottom: 30px;
}
.blue {
  margin-top: 8px;
  font-weight: 600;
  color: var(--RootColor);
}
</style>
<style>
.el-backtop, .el-calendar-table td.is-today {
  color: var(--RootColor) !important;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
</style>
