<template>
  <div class="wrap">
    <div class="custom-wrap core">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="custom swiper-slide" v-for="item in list" :key="item.name">
            <div class="pic">
              <el-carousel class="car" arrow="never" :autoplay="autoplay">
                <el-carousel-item class="car-item" v-for="img in item.images" :key="img.url">
                  <img :src="img.url" />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="c-inner">
              <p class="c-title">{{ item.name }}</p>
              <p class="c-text">{{ item.text }}</p>
              <div class="btn opcity" @click="custom">了解更多案例</div>
            </div>
          </div>
        </div>
      </div>
      <img class="left-icon icon opcity" @mouseover="mouseOver()" @mouseleave="mouseLeave()" @click="next" src="../../assets/img/home/left-h.png" />
      <img class="right-icon icon opcity" @mouseover="mouseOver()" @mouseleave="mouseLeave()" @click="prev" src="../../assets/img/home/right-h.png" />
      <img class="custom-bg" src="../../assets/img/home/custom-bg.png" />
    </div>
  </div>
</template>
//home.city.electric.uav三个页面最底下的切换组件
<script>
export default {
  data() {
    return {
      list: [
        {
          name: "台州市某供电所",
          text: "开展无人机基于固定机巢的智能巡检技术，实现供电所线路智能巡检全覆盖，相比于传统人工运维提高30倍以上工作效率，相比于单兵无人机作业提高6倍以上作业效率。",
          images: [
            {
              url: "https://cdn.aihangtec.com/index/image/custom/供电所机巢/g1.jpg"
            },
            {
              url: "https://cdn.aihangtec.com/index/image/custom/供电所机巢/g2.jpg"
            },
            {
              url: "https://cdn.aihangtec.com/index/image/custom/供电所机巢/g3.jpg"
            },
            {
              url: "https://cdn.aihangtec.com/index/image/custom/供电所机巢/g4.jpg"
            }
          ]
        },
        {
          name: "浙江省某电力施工现场",
          text: "利用大载重无人机开展物资运输工作，可实现150kg材料单次物资运输工作，节省大量人力物力同时缩短工程时间。",
          images: [
            {
              url: "https://cdn.aihangtec.com/index/image/custom/重载无人机/c3.jpg"
            },
            {
              url: "https://cdn.aihangtec.com/index/image/custom/重载无人机/c2.jpg"
            },
            {
              url: "https://cdn.aihangtec.com/index/image/custom/重载无人机/c1.jpg"
            }
          ]
        },
        {
          name: "江苏省某镇",
          text: "开展无人机日常巡检、定时巡逻工作并针对街道违章、垃圾堆放、污水排放等现场进行自动识别及时反馈网格长。",
          images: [
            {
              url: "https://cdn.aihangtec.com/index/image/custom/河道巡检/h2.jpg"
            },
            {
              url: "https://cdn.aihangtec.com/index/image/custom/河道巡检/h1.jpg"
            },
            {
              url: "https://cdn.aihangtec.com/index/image/custom/河道巡检/h3.jpg"
            }
          ]
        },
        {
          name: "台州市某公安",
          text: "开展空天地一体化联动，通过单兵无人机、固定机巢、移动机巢开展快速巡检工作，可在指挥室进行远程控制、远程监管、远程喊话等工作。",
          images: [
            {
              url: "https://cdn.aihangtec.com/index/image/custom/公安/g3.jpg"
            },
            {
              url: "https://cdn.aihangtec.com/index/image/custom/公安/g4.jpg"
            }
          ]
        },
        {
          name: "山西省某山林",
          text: "通过固定翼无人机开展大面积常态化巡检作业，主要通过指挥室下发作业任务定期开展山林火源定位、日常监测。同时配合便携式无人机进行低空巡逻，载重无人机进行物资运输响应火灾救援等工作。",
          images: [
            {
              url: "https://cdn.aihangtec.com/index/image/custom/林业/l1.JPG"
            },
            {
              url: "https://cdn.aihangtec.com/index/image/custom/林业/l2.png"
            },
            {
              url: "https://cdn.aihangtec.com/index/image/custom/林业/l3.png"
            }
          ]
        }
      ],
      swiper: {},
      autoplay: true
    }
  },
  mounted() {
    this.swiper = new this.Swiper(".swiper-container", {
      spaceBetween: 100
      // loop: true
    })
  },
  methods: {
    mouseLeave() {
      this.autoplay = true
    },
    mouseOver() {
      this.autoplay = false
    },
    next() {
      this.swiper.slidePrev()
    },
    prev() {
      this.swiper.slideNext()
    },
    custom() {
      this.$router.push("/custom")
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style scoped lang="scss">
.opcity {
  @media screen and (max-width: 540px) {
    display: none;
  }
  @media screen and (min-width: 540px) {
    display: block;
  }
}
.swiper-container {
  width: 100%;
  padding: 20px;
  overflow: hidden;
  @media screen and (max-width: 540px) {
    height: 220px;
  }
  @media screen and (min-width: 540px) {
    height: 600px;
  }
  user-select: none;
}
.icon {
  position: absolute;
  width: 40px;
  cursor: pointer;
  filter: grayscale(1);
  transition: all 0.5s;
}
.icon:hover {
  filter: grayscale(0);
}
.left-icon {
  left: -80px;
  top: 50%;
}
.right-icon {
  right: -90px;
  top: 50%;
}
.custom-wrap {
  position: relative;
  user-select: none;
  .custom-bg {
    position: absolute;
    @media screen and (max-width: 540px) {
      right: 12px;
      top: -5px;
      width: 100px;
      height: 70px;
    }
    @media screen and (min-width: 540px) {
      right: -50px;
      top: 30px;
      width: 180px;
      height: 145px;
    }
  }
  .custom {
    position: relative;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 540px) {
      top: 0px;
      height: 180px;
    }
    @media screen and (min-width: 540px) {
      top: 80px;
      height: 484px;
    }
    background: #ffffff;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    border-radius: 8px;
    z-index: 99;
    .pic {
      .car {
        @media screen and (max-width: 540px) {
          width: 45vw;
          height: 180px;
        }
        @media screen and (min-width: 540px) {
          width: 600px;
          height: 484px;
        }
        .car-item {
          @media screen and (max-width: 540px) {
            width: 45vw;
            height: 180px !important;
          }
          @media screen and (min-width: 540px) {
            width: 600px;
            height: 484px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .c-inner {
      position: relative;
      @media screen and (max-width: 540px) {
        padding: 14px 10px;
      }
      @media screen and (min-width: 540px) {
        padding: 40px 54px;
      }
      width: 100%;
      .c-title {
        @media screen and (max-width: 540px) {
          font-size: 12px;
          margin: 5px 0 0;
          line-height: 22px;
        }
        @media screen and (min-width: 540px) {
          font-size: 28px;
          margin: 20px 0;
          line-height: 42px;
        }
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 42px;
        letter-spacing: 1px;
      }

      .c-text {
        @media screen and (max-width: 540px) {
          font-size: 10px;
          padding-top: 10px;
          line-height: 16px;
        }
        @media screen and (min-width: 540px) {
          font-size: 18px;
          padding-top: 20px;
          line-height: 30px;
        }
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
      }

      .btn {
        position: absolute;
        bottom: 72px;
        right: 54px;
        width: 150px;
        height: 36px;
        background: #0a84ff;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 34px;
        text-align: center;
        transition: all 0.2s;
        cursor: pointer;
      }
    }
  }
}

::v-deep .el-carousel__container {
  @media screen and (max-width: 540px) {
    height: 180px !important;
  }
  @media screen and (min-width: 540px) {
    height: 484px;
  }
}
::v-deep .el-carousel__button {
  @media screen and (max-width: 540px) {
    width: 18px !important;
  }
}
::v-deep .el-carousel__indicator--horizontal {
  @media screen and (max-width: 540px) {
    padding: 0px 0px !important;
  }
}
</style>
