<template>
  <footer class="footer">
    <div class="footer-wrap">
      <div class="logo">
        <img src="../../assets/img/header/logo-t.png" alt="logo" />
      </div>
      <div>
        <ul>
          <li class="bold">联系方式</li>
          <li class="content mb-2">服务热线 0571-87858605</li>
          <li class="bold">工作时间</li>
          <li class="content">周一 至 周五 9:00 至 18:00</li>
        </ul>
      </div>
      <div>
        <ul>
          <li class="bold">公司地址</li>
          <li class="content mb-2">浙江省杭州市余杭区中国杭州5G创新园413</li>
          <li class="bold">邮件</li>
          <li class="content">xuanchaoyue@aihangtec.com</li>
        </ul>
      </div>
      <!-- <div class="code">
        <img src="../../assets/img/footer/qrcode.png" alt="qrcode" />
        <p>公众号</p>
      </div> -->
    </div>
    <div class="footer-wrap-media">
      <div class="logo">
        <img src="../../assets/img/header/logo-t.png" alt="logo" />
      </div>
      <div>
        <ul>
          <li class="bold">联系方式</li>
          <li class="content">服务热线 0571-87858605</li>
          <li class="bold">工作时间</li>
          <li class="content">周一 至 周五 9:00 至 18:00</li>
        </ul>
      </div>
      <div>
        <ul>
          <li class="bold">公司地址</li>
          <li class="content">浙江省杭州市余杭区中国杭州5G创新园413</li>
          <li class="bold">邮件</li>
          <li class="content">xuanchaoyue@aihangtec.com</li>
        </ul>
      </div>
    </div>
    <div class="copyRight">杭州艾航科技有限公司 版权所有 2015-2022 <a target="_balnk"
        href="https://beian.miit.gov.cn/?site=brandsite&from=footer#/Integrated/index">浙ICP备17038148号</a></div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {}
  },
  mounted() { },
  methods: {}
}
</script>

<style scoped lang="scss">
$main-width: 1200px;
$hight: 300px;

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  line-height: 23px !important;
}

.footer {
  width: 100%;
  background: #272727;
  transition: all 0.1s;
}

.footer-wrap-media {
  @media screen and (min-width: 540px) {
    display: none;
  }

  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  font-weight: 400;
  color: #fff;
  border-bottom: solid 1px rgba(255, 255, 255, 0.15);

  &>div {
    margin-right: 3%;
  }

  &>div:first-child {
    margin-left: 3%;
  }

  &>div:last-child {
    margin-right: 0%;
  }

  .logo {
    img {
      width: 80px;
      height: 22px;
      position: relative;
      top: 3px;
    }
  }

  .bold {
    font-weight: 600;
    font-size: 10px;
  }

  .content {
    margin: 2px 0 10px;
    font-size: 8px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    transition: all 0.3s;
  }
}

.footer-wrap {
  position: relative;

  @media screen and (max-width: 540px) {
    display: none;
  }

  display: flex;
  justify-content: flex-start;
  width: $main-width;
  margin: 0 auto;
  padding: 60px 0;
  font-weight: 400;
  color: #fff;
  border-bottom: solid 1px rgba(255, 255, 255, 0.15);

  &>div {
    margin-right: 120px;
  }

  .content {
    margin-top: 7px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    transition: all 0.3s;
  }

  .logo {
    img {
      width: 200px;
      height: 56px;
    }
  }

  .code {
    position: absolute;
    right: 80px;
    margin: 0 !important;

    p {
      text-align: center;
      font-size: 14px;
    }

    img {
      width: 80px;
      height: 80px;
    }
  }

  .bold {
    font-weight: 600;
  }
}

.copyRight {
  text-align: center;
  color: rgba(255, 255, 255, 0.65);
  font-weight: 400;

  a {
    color: rgba(255, 255, 255, 0.65) !important;
  }

  @media screen and (max-width: 540px) {
    font-size: 10px;
    line-height: 40px;
  }

  @media screen and (min-width: 540px) {
    font-size: 14px;
    line-height: 70px;
  }
}
</style>
