<template>
  <div class="touch-mask" v-show="flag" @click="maskClose" @touchmove.prevent>
    <transition mode="out-in" name="el-zoom-in-center" appear>
      <div v-show="flag" class="touch">
        <div class="inner">
          <div class="tip mb">
            <div>
              <i class="el-icon-phone-outline"></i>
            </div>
            <div>
              <p>电话咨询</p>
              <p class="blue">0571-87858605</p>
            </div>
          </div>
          <div class="tip mb">
            <div>
              <i class="el-icon-message"></i>
            </div>
            <div>
              <p>邮件咨询</p>
              <p class="blue">xuanchaoyue@aihangtec.com</p>
            </div>
          </div>
          <div class="tip">
            <div>
              <i class="el-icon-chat-round"></i>
            </div>
            <div>
              <p>扫一扫联系我们</p>
              <div>
                <img class="code" src="../../assets/img/fixed/qrcode.jpg" />
              </div>
            </div>
          </div>
        </div>
        <div class="close" @click="close">
          <i class="el-icon-circle-close"></i>
        </div>
      </div>
    </transition>
  </div>
</template>
// 此为移动端联系我们弹窗，移动端点击顶部联系我们，弹出此组件
<script>
export default {
  name: "Dialog",
  data() {
    return {
      flag: false
    }
  },
  mounted() {},
  methods: {
    show() {
      this.flag = !this.flag
    },
    maskClose(e) {
      if (e.target.className == "touch-mask") this.flag = false
    },
    close() {
      this.flag = false
    }
  }
}
</script>

<style scoped lang="scss">
.touch-mask {
  position: fixed;
  z-index: 99999;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.touch {
  position: fixed;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 280px;
  height: 384px;
  background: #ffffff;
  border-radius: 12px;
  z-index: 9999;
}
.inner {
  padding: 0px 10px;
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}
.close {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 44px;
  color: #fff;
  cursor: pointer;
  i {
    float: right;
  }
}
.tip {
  display: flex;
  justify-content: flex-start;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;

  i {
    margin-right: 6px;
  }
}
.code {
  width: 90px;
  height: 90px;
  margin-top: 8px;
}
.mb {
  margin-bottom: 10px;
}
.blue {
  margin-top: 8px;
  font-weight: 600;
  color: #3b51e4;
}
</style>
