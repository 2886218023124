import Vue from "vue"
import VueRouter from "vue-router"
import { setTitle } from "../utils/util"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "",
      index: 0
    },
    component: () => import("../views/home/Home.vue")
  },
  {
    path: "/electric",
    name: "Electric",
    meta: {
      title: "智慧电力",
      index: 1,
      type: 1
    },
    component: () => import("../views/electric/electric.vue")
  },
  {
    path: "/city",
    name: "city",
    meta: {
      title: "智慧城市",
      index: 1,
      type: 2
    },
    component: () => import("../views/city/city.vue")
  },
  {
    path: "/uav",
    name: "uav",
    meta: {
      title: "无人机多行业应用",
      index: 1,
      type: 3
    },
    component: () => import("../views/uav/uav.vue")
  },
  {
    path: "/custom",
    name: "Custom",
    meta: {
      title: "客户案例",
      index: 2
    },
    component: () => import("../views/custom/custom.vue")
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "关于我们",
      index: 3
    },
    component: () => import("../views/About/About.vue")
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) setTitle(to.meta.title)
  next()
})

export default router
